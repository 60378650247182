import dayjs from '@troon/dayjs';
// eslint-disable-next-line import/no-unresolved
import { createAsync, useLocation, useNavigate } from '@solidjs/router';
import {
	BreadcrumbItem,
	Breadcrumbs,
	Heading,
	Picture,
	Container,
	Section,
	Button,
	Link,
	TroonAccess,
} from '@troon/ui';
import { createMemo, Show, Suspense } from 'solid-js';
import { Meta, Title } from '@solidjs/meta';
import { getConfigValue } from '../../modules/config';
import { createContentfulRequest } from '../content/[model]/_client';
import { RichText, richTextToPlain } from '../../components/rich-text';
import { NotFoundContent } from '../../partials/404';
import { ShareButtons } from '../../components/share-buttons';
import type { RouteSectionProps } from '@solidjs/router';

export default function PressReleasePage(props: RouteSectionProps) {
	const loc = useLocation();
	const navigate = useNavigate();
	const data = createAsync(async () => getArticle(props.params.insiderArticle!), { deferStream: true });

	const shareData = createMemo<ShareData>(() => ({
		title: data()?.fields.title,
		text: data()?.fields.title,
		url: `https://${getConfigValue('HOST')}/insider/${data()?.fields.slug}`,
	}));

	return (
		<Show when={data()?.fields} fallback={<NotFoundContent />}>
			{(data) => (
				<>
					<Title>{data().title} | Press Release | Troon</Title>
					<Meta
						name="description"
						content={data().content ? `${richTextToPlain(data().content!).slice(0, 154)}…` : undefined}
					/>
					<Meta name="og:title" content={`${data().title} | Troon`} />
					<Meta name="og:url" content={`https://${getConfigValue('HOST')}/insider/${data().slug}`} />
					<Meta name="og:type" content="article" />
					<Meta name="article:published_time" content={dayjs(data().publishDate).toISOString()} />
					<Meta name="og:image" content={`https://${data().heroImage?.fields.file?.url}`} />

					<Container class="pb-16 pt-4">
						<Breadcrumbs omitHome>
							<BreadcrumbItem href="/insider">The Troon Insider</BreadcrumbItem>
							<BreadcrumbItem href={loc.pathname}>{data().title}</BreadcrumbItem>
						</Breadcrumbs>
					</Container>

					<Container size="small" class="flex flex-col gap-8">
						<Heading
							as="h1"
							// eslint-disable-next-line tailwindcss/no-arbitrary-value
							class="lg:max-w-[65%]"
						>
							{data().title}
						</Heading>
						<div class="flex flex-wrap justify-between gap-4">
							<time class="text-lg">{dayjs(data().publishDate, 'America/Phoenix').format('MMMM D, YYYY')}</time>
							<ShareButtons title={shareData().title} text={shareData().text} url={shareData().url} />
						</div>
					</Container>

					<div class="container mx-auto my-8 max-w-screen-2xl md:px-0 2xl:px-8">
						<Suspense>
							<Picture
								preload
								src={data().heroImage?.fields.file?.url ? `https://${data().heroImage?.fields.file?.url}` : undefined}
								width={1440}
								height={576}
								sizes="95vw"
								class="w-full 2xl:rounded"
								alt={data().heroImage?.fields.description ?? data().heroImage?.fields.title ?? ''}
								crop="face"
							/>
						</Suspense>
					</div>

					<Section>
						<Suspense>
							<Show when={data().content}>
								{(content) => (
									<Container size="small">
										<RichText document={content()} />
									</Container>
								)}
							</Show>
						</Suspense>

						<Container size="small">
							<div
								class="relative flex cursor-pointer flex-wrap items-center justify-between gap-4 rounded bg-gradient-to-r from-neutral-950 to-brand-700 p-6 text-white md:flex-nowrap md:gap-12"
								onClick={() => {
									navigate('/access');
								}}
							>
								<div class="flex shrink flex-col items-start gap-4">
									<TroonAccess class="w-52 md:w-64" />
									<p>Save up to 50% on tee times at 150+ courses with the all-new Troon Access program.</p>
								</div>
								<Button as={Link} href="/access" appearance="secondary-current">
									Learn more<span class="sr-only"> about Troon Access</span>
								</Button>
							</div>
						</Container>
					</Section>
				</>
			)}
		</Show>
	);
}

const getArticle = createContentfulRequest('articles', { 'fields.articleType': 'The Troon Insider' });
